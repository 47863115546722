import './term.scss';

import React from 'react';
import { Link, graphql } from 'gatsby';
import { kebabCase } from 'lodash';

import * as Core from 'components/core';
import Layout from 'components/layout/Layout';
import FractionBar from 'components/FractionBar';
import ThumbnailFrameworkGraph from 'components/tree-diagrams/ThumbnailFrameworkGraph';

import { Framework, Term } from 'types';

interface Props {
  data: {
    allFrameworks: {
      frameworks: Framework[];
    };
    term: Term;
    allTerms: {
      terms: Term[];
    };
  };
  pageContext: {
    identifier: number;
    name: string;
  };
}

export default class TermPage extends React.Component<Props> {
  findTermByID(id: number): Term | null {
    return (
      this.props.data.allTerms.terms.find((t) => t.identifier === id) || null
    );
  }

  get terms(): Term[] {
    return this.props.data.allTerms.terms;
  }

  get term(): Term {
    return this.props.data.term;
  }

  get frameworks(): Framework[] {
    return this.props.data.allFrameworks.frameworks;
  }

  findFrameworkByID(id: number): Framework {
    return this.frameworks.find((f) => f.identifier === id) as Framework;
  }

  get framework(): Framework {
    return this.findFrameworkByID(this.term.frameworkID) as Framework;
  }

  get termsWithTheSameName(): Term[] {
    const term = this.term;
    return this.terms.filter(
      (t) =>
        t.includeInThesaurus &&
        t.identifier !== term.identifier &&
        kebabCase(t.name) === kebabCase(term.name)
    );
  }

  termsForFrameworkWithID(frameworkID: number): Term[] {
    return this.terms.filter((t) => t.frameworkID === frameworkID);
  }

  get relatedTerms(): (Term & { relatedness: number })[] {
    const term = this.props.data.term;
    if (term.relatedTerms == null || term.relatedTerms.length === 0) {
      return [];
    }

    const terms = term.relatedTerms.map((t) => {
      // TODO: fix type
      const relatedTerm = this.findTermByID(t.identifier) as any;
      if (relatedTerm != null) {
        relatedTerm.relatedness = t.relatedness;
      }
      return relatedTerm;
    });

    return terms
      .filter((t) => t != null)
      .sort((a, b) => b.relatedness - a.relatedness);
  }

  render() {
    const { term, framework, termsWithTheSameName } = this;

    // We expect this to always be present, but without this check the there's
    // a crash when building for production. If that can be resolved another way
    // this should be removed.
    if (this.props.data.allFrameworks.frameworks == null) {
      return null;
    }

    return (
      <Layout>
        <div className="TermPage">
          <div className="TermPage-group">
            <div className="TermPage-group-left">
              <div className="TermPage-heading">
                <Link
                  to={`/frameworks/${framework.identifier}/`}
                  className="TermPage-heading-framework"
                >
                  <div className="TermPage-heading-framework-icon">
                    <ThumbnailFrameworkGraph
                      framework={framework}
                      terms={this.termsForFrameworkWithID(
                        this.framework.identifier
                      )}
                      width={26}
                      height={26}
                    />
                  </div>
                  <div>{framework.name}</div>
                </Link>

                <h1 className="TermPage-heading-term">{term.name}</h1>
              </div>

              <div className="TermPage-term-definition">
                <Core.Markdown>{term.definition}</Core.Markdown>
              </div>

              <div className="TermPage-moreDetails">
                <div className="TermPage-moreDetails-section">
                  <h4>Related Measures</h4>
                  <p className="TermPage-moreDetails-section-placeholder">
                    Forthcoming
                  </p>
                </div>
                <div className="TermPage-moreDetails-section">
                  <h4>Related Programs & Strategies</h4>
                  <p className="TermPage-moreDetails-section-placeholder">
                    Forthcoming
                  </p>
                </div>
                <div className="TermPage-moreDetails-section">
                  <h4>Related Resources</h4>
                  <p className="TermPage-moreDetails-section-placeholder">
                    Forthcoming
                  </p>
                </div>
              </div>
            </div>

            {termsWithTheSameName.length > 0 && (
              <div className="TermPage-group-right no-print">
                <h4>Also Defined In</h4>
                {termsWithTheSameName.map((termWithSameName) => (
                  <p
                    className="TermPage-secondary-framework"
                    key={termWithSameName.identifier}
                  >
                    <Link
                      className="TermPage-secondary-framework-link"
                      to={`/terms/${termWithSameName.identifier}/`}
                    >
                      {
                        this.findFrameworkByID(termWithSameName.frameworkID)
                          .name
                      }
                    </Link>
                  </p>
                ))}

                <Link
                  to={`/terms/named/${kebabCase(term.name)}`}
                  className="TermPage-group-right-link"
                >
                  View All Definitions
                </Link>
              </div>
            )}
          </div>

          {term.relatedTerms != null && term.relatedTerms.length > 0 && (
            <div className="TermPage-related-terms">
              <h2>Related Terms</h2>
              <div className="TermPage-related-terms-row-heading no-print">
                <h4 className="TermPage-related-terms-row-heading-label-term">
                  Term
                </h4>
                <h4 className="TermPage-related-terms-row-heading-label-framework">
                  Framework
                </h4>
                <h4 className="TermPage-related-terms-row-heading-label-graph">
                  Relatedness
                </h4>
              </div>
              {this.relatedTerms.map((relatedTerm) => (
                <div
                  key={relatedTerm.identifier}
                  className="TermPage-related-terms-row"
                >
                  <div className="TermPage-related-terms-row-term">
                    <Link to={`/terms/${relatedTerm.identifier}/`}>
                      {relatedTerm.name}
                    </Link>
                  </div>
                  <div className="TermPage-related-terms-row-framework">
                    <Link to={`/frameworks/${relatedTerm.frameworkID}/`}>
                      {relatedTerm.frameworkName}
                    </Link>
                  </div>
                  <div className="TermPage-related-terms-row-graph">
                    <FractionBar fraction={relatedTerm.relatedness} />
                  </div>
                </div>
              ))}

              <p className="no-print">
                <Link to="/faq/#calculating-related-terms">
                  How is the relatedness between two terms calculated?
                </Link>
              </p>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query TermBySlug($identifier: Int!) {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        name
        color
      }
    }
    term: termsJson(identifier: { eq: $identifier }) {
      identifier
      name
      frameworkName
      frameworkID
      definition
      relatedTerms {
        identifier
        relatedness
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkName
        frameworkID
        parentID
        includeInThesaurus
      }
    }
  }
`;
