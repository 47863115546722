import './FractionBar.scss';

import React from 'react';

import PrintBackground from 'components/core/PrintBackground';

interface Props {
  fraction: number;
}

/**
 * A single value visualization showing a fraction (or percentage) complete. The
 * value given should be between 0 and 1.
 */
const FractionBar: React.FC<Props> = (props) => {
  return (
    <div className="FractionBar">
      <PrintBackground color="#DFE2E5" />
      <div
        className="FractionBar-indicator"
        style={{ width: `${props.fraction * 100}%` }}
      >
        <PrintBackground color="#913592" />
      </div>
    </div>
  );
};

export default FractionBar;
